import React, { Component } from 'react';

import Router from './newcomps/Router';

class App extends Component {
  render() {
  return(
    <div>      
       <Router/>
    </div>
    
  );
  }
}

export default App;