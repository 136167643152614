/*******************************************************************************************
Copyright © 2019 Xiippy.ai. All rights reserved. Australian patents awarded. PCT patent pending.

NOTES:

- No payment gateway SDK function is consumed directly. Interfaces are defined out of such interactions and then the interface is implemented for payment gateways. Design the interface with the most common members and data structures between different gateways. 
A proper factory or provider must instantiate an instance of the interface that is interacted with.
- Any major change made to SDKs should begin with the c# SDK with the mindset to keep the high-level syntax, structures and class names the same to minimise porting efforts to other languages. Do not use language specific features that don't exist in other languages. We are not in the business of doing the same thing from scratch multiple times in different forms.
- Pascal Case for naming conventions should be used for all languages
- No secret or passwords or keys must exist in the code when checked in

*******************************************************************************************/
import React from 'reactn';
import { Container, Row, Col, Card, Button, Image, DropdownButton, Dropdown} from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';

class ViewCart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Cart : [],
            TotalAmount : 0,  
            TotalTax: 0,
            isLoggedIn: false,
        };
       
    }

    checkUser = async () => {
        try {

            var user = await Auth.currentAuthenticatedUser();
            if (user != null) {
                
                this.setState({ isLoggedIn: true });
            }
        }
        catch (x) {
            console.log('error at currentUserCredentials(): ' + x);

        }

        
    }
    componentDidMount() {
        
      const saved = localStorage.getItem('ShoppingCart');
      const CartArray = JSON.parse(saved);        
       
       let sum = 0;        
       let tax = 0;
       let total_tax = 0;

       if(CartArray && CartArray.length > 0){
           for (let i = 0; i < CartArray.length; i++){
               
               sum =  Number((sum + CartArray[i].Total).toFixed(2));

               tax = CartArray[i].Tax
               total_tax = Number((total_tax + tax).toFixed(2));
            
            }
        }else{
          
          alert("Cart is empty!")
          
        }     
       
            
       this.setState({ Cart: CartArray });
       this.setState({ TotalAmount : sum });     
        this.setState({ TotalTax: total_tax });
        this.checkUser()

        
    }

    

    handleQtyUpdate = (event, index, price) => {
      
      const target = event.target;
      const value = target.value;      
      let total = 0;
      let tax = 0;
      let total_tax= 0;
      let total_amount = 0;



      
      total = Number((value * price).toFixed(2));

      total_amount = Number(((this.state.TotalAmount - this.state.Cart[index].Total) + total).toFixed(2));

      this.state.Cart[index].Total = total;
      this.setState({ TotalAmount : total_amount });


      tax = Number((total * this.state.Cart[index].TaxRate).toFixed(2));
      total_tax = Number(((this.state.TotalTax - this.state.Cart[index].Tax) + tax).toFixed(2));
      console.log("total_tax",total_tax)
      this.state.Cart[index].Tax = tax;
      this.setState({ TotalTax : total_tax });     
      
      
      
      this.state.Cart[index].Qty = value;    
      this.forceUpdate()

      

     
    }
      handleDeleteCartItem = (index) =>{ 
        


        if (index > -1) { //Make sure item is present in the array, without if condition, -n indexes will be considered from the end of the array.
          
          
          this.setState({ Cart: arrayTemp, TotalAmount: Number((this.state.TotalAmount - this.state.Cart[index].Total).toFixed(2))  });

          
          this.setState({Cart:arrayTemp , TotalTax : Number((this.state.TotalTax - this.state.Cart[index].Tax).toFixed(2))  });

          var arrayTemp=this.state.Cart;
          this.setState({Cart:[]});
          console.log('array before:',arrayTemp);
          arrayTemp.splice(index, 1);
          console.log('array after:',arrayTemp);
          this.setState({Cart:arrayTemp });
          
          localStorage.setItem('ShoppingCart',JSON.stringify(this.state.Cart));
          
          
        }    
       
      }
    
    onSelectionHandle =() =>{ 
       
      localStorage.setItem('ShoppingCart',JSON.stringify(this.state.Cart));
      this.props.history.push('/ProductsList/ViewCart/CheckOut');
    }
    handleAccountSettingShow = () => {
        this.props.history.push('/AccountSettings');
    }
    handleSignOut = () => { }
    render() {
        return (
          
          <Card>
               
                <div class="card-header container-fluid">
                    <div class="row">
                        <div class="col-md-11">
                            <h3>Shopping Cart:</h3>
                        </div>
                        {!this.state.isLoggedIn && (

                            <div class="col-md-1" >
                                <a href="#" aria-hidden="true" onClick={(e) => { e.preventDefault(); this.handleSignIn(); }}> Sign In</a>

                            </div>
                        )}
                        {this.state.isLoggedIn && (

                            <div class="col-md-1">
                                <DropdownButton
                                    title="Action"
                                    id="dropdown-menu-align-right">
                                    <Dropdown.Item eventKey="1" onClick={() => { this.handleAccountSettingShow(); }}>Account Settings</Dropdown.Item>
                                    <Dropdown.Item eventKey="1" onClick={() => { this.handleSignOut(); }}>Sign Out</Dropdown.Item>
                                </DropdownButton>

                            </div>
                        )}
                       

                    </div>

                </div>
                <Card.Body>                       
                
                  <Container>
                   
                   
                    {this.state.Cart.map(((item, index) =>
                        <div>
                            {index < 1 && (

                                <Row>                                    
                                    <Col className="d-none d-xl-block d-lg-block  d-md-block" xs={12} sm={12} md={2} lg={2} xl={2}>  <h6>Title</h6> </Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={2} lg={2} xl={2}><h6>Description</h6></Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={1} lg={1} xl={1}><h6>Price</h6></Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={1} lg={1} xl={1}><h6>Qty</h6></Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={1} lg={1} xl={1}><h6>Tax</h6></Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={2} lg={2} xl={2}><h6>Total</h6></Col>
                                    <hr className="d-none d-xl-block d-lg-block d-md-block" style={{ color: 'black', backgroundColor: 'black', height: 1 }} />
                                </Row>

                            )}
                            <Row>
                                <Col xs={12} md={2} key={'details_'+item.ProductID}>                     
                                    <p>
                                        {(item.Image && (<Image src={item.Image} height='30px' width='30px' />))}
                                       {item.Title}
                                    </p>
                                </Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Description:</h6></Col>
                                <Col xs={8} sm={8} md={2} key={'description_' + item.ProductID}>{item.Description}</Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Price:</h6></Col>
                                <Col xs={8} sm={8} md={1} key={'price_' + item.ProductID}>{item.Price}</Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Qty:</h6></Col>
                                <Col xs={8} sm={8} md={1} key={'qty_'+item.ProductID}>
                                    <input type="number" name={"Qty" + index} id={"Qty" + index} style={{ width: "50px" }} min="0" value={item.Qty}  onChange={e => this.handleQtyUpdate(e, index,item.Price)} />
                                </Col>
                               
                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Tax:</h6></Col>
                                <Col xs={8} sm={8} md={1} key={'tax_' + item.ProductID}> {item.Tax}</Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Total:</h6></Col>
                                <Col xs={8} sm={8} md={1} key={'total_'+item.ProductID}> {item.Total}</Col>
                                                  
                       
                                <Col xs={12} md={2} key={'del_'+item.ProductID}>
                                    <Button variant="secondary" size="sm"  onClick={(e) =>{this.handleDeleteCartItem(index)}}>&times;</Button>                     
                                </Col>
                            </Row>
                    
                         </div>
                    ))}
                     <Row>
                          <hr style={{color: 'black',backgroundColor: 'black',height: 1}}/>
                     </Row>
                        <Row>
                            <Col xs={3} md={7}>Total : </Col>
                            <Col xs={9} md={3}>{this.state.TotalAmount}</Col>               
                        </Row>
                        
                        <Row>
                             <Col xs={3} md={6}>Tax : </Col>
                             <Col xs={9} md={6}>{this.state.TotalTax}</Col>
                        </Row>
                           
                        
                  </Container>
                  <Button variant="primary" style={{float: 'right'}} onClick={(e) => { e.preventDefault(); this.onSelectionHandle(); }}>Next</Button>
            
                </Card.Body>

            
           </Card>
   
        );
    }
}






export default withRouter(ViewCart);
