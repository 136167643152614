/*******************************************************************************************
Copyright © 2019 Xiippy.ai. All rights reserved. Australian patents awarded. PCT patent pending.

NOTES:

- No payment gateway SDK function is consumed directly. Interfaces are defined out of such interactions and then the interface is implemented for payment gateways. Design the interface with the most common members and data structures between different gateways. 
A proper factory or provider must instantiate an instance of the interface that is interacted with.
- Any major change made to SDKs should begin with the c# SDK with the mindset to keep the high-level syntax, structures and class names the same to minimise porting efforts to other languages. Do not use language specific features that don't exist in other languages. We are not in the business of doing the same thing from scratch multiple times in different forms.
- Pascal Case for naming conventions should be used for all languages
- No secret or passwords or keys must exist in the code when checked in

*******************************************************************************************/
import React from 'reactn';
import { withRouter } from 'react-router';
import { Container, Row, Col, Card, Button, Alert, Image, DropdownButton, Dropdown} from 'react-bootstrap';
import { Auth } from 'aws-amplify';


class ProductsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
              
              products :[],
              CartArray : [],             
              TotalAmount : 0,
              TotalNumber: 0,
              TotalTax: 0,
              isLoggedIn: false,
              
        };
       
    };


    checkUser= async () => {
        try {

            var user = await Auth.currentAuthenticatedUser();
            if (user != null) {
                this.setState({ isLoggedIn: true });
            }
        }
        catch (x) {
            console.log('error at currentUserCredentials(): ' + x);
            
        }
    }

    componentDidMount() {
      const savedProduct = localStorage.getItem('AllProducts');
      const ProductsList = JSON.parse(savedProduct);  

        let sum = 0;
        let total_tax = 0;
        let tax = 0;
  
      for (let i = 0; i < ProductsList.length; i++) {
          if (ProductsList[i].isSelected ){                
              sum = Number((sum + ProductsList[i].Total).toFixed(2));
              total_tax = Number((total_tax + ProductsList[i].Tax).toFixed(2));
          }
      }
      this.setState({ products: ProductsList }); 
      this.setState({ TotalAmount: sum });
      this.setState({ TotalTax: total_tax });
      

       let counter = 0;
       const savedCart = localStorage.getItem('ShoppingCart');
       const CartArr = JSON.parse(savedCart);  
       this.setState({ CartArray: CartArr });
       
       for (let j = 0; j < CartArr.length; j++) {                    
          counter = counter + 1; 
          
        }
        this.setState({ TotalNumber: counter });

        this.checkUser()
        
    
    };
    
    DeleteCartItem = (id) =>{    
      
      for (let index = 0; index < this.state.CartArray.length; index++) {        
        if (id === this.state.CartArray[index].ProductID) {
          this.state.CartArray.splice(index, 1);
          this.setState({TotalNumber : this.state.TotalNumber - 1})  

        }
      }
    };

    handleCheckBox  = (event, index, Id) => {
        const target = event.target;
        const checked = target.checked;
        

        if(checked == false){
          this.DeleteCartItem(Id)
          
        }

        this.state.products[index].isSelected = checked
        this.forceUpdate()   
      
    };

    isDuplicate = (obj) => {
      return this.state.CartArray.find(data => data.ProductID === obj.ProductID && data.Qty === obj.Qty);
     }; 
     
     isQuantityChange = (obj) => {            
         return this.state.CartArray.find(data => data.ProductID === obj.ProductID && data.Qty !== obj.Qty);
         
     }; 
     
    UpdateCartItem =(obj) =>{    
        
         let index = this.state.CartArray.findIndex(data => data.ProductID === obj.ProductID);
         this.state.CartArray[index].Qty = obj.Qty;
         this.state.CartArray[index].Total = obj.Total;
         this.forceUpdate()
      
    }
    
    AddItemToCart= (obj) => { 
      
      if(this.state.CartArray && this.state.CartArray.length > 0) { 
        if (this.isDuplicate(obj)) { 
          return(false)
        }
        
        if(this.isQuantityChange(obj)){          
          this.UpdateCartItem(obj)
          return(false)
        }
      }

      
      this.state.CartArray.push(obj)
      
      return(true)
        
           
    }



    handleAddToCart= (obj, checked) => {  
      
      if (checked) { 
         if(this.AddItemToCart(obj)){
          this.setState({TotalNumber : this.state.TotalNumber + 1})
         }
         
         
      }else{
        alert("Please select item!")
      }
    };

    AddSelectedToCart =() =>{     
      
      let counter = 0;
      for(let index=0; index < this.state.products.length; index++){
        if (this.state.products[index].isSelected ) { 
           this.AddItemToCart(this.state.products[index]) 
           counter = counter + 1;
                
        }       
     }
     this.setState({TotalNumber : counter}) 
    };

    handleQtyUpdate = (event, index,price, tax_rate) => {
      
        const target = event.target;
        const value = target.value; 
        
        
        var total =0;             
        var sum = 0;
        var prvtotal = 0;
        
        var tax = 0;
        var total_tax = 0;
        var prvtax = 0;


       

        prvtotal = this.state.products[index].Total;
        prvtax = this.state.products[index].Tax;

        this.state.products[index].Qty = value;
             
     
       total = Number((value*price).toFixed(2));
       console.log("total", total,prvtotal)
       
        sum = Number(((this.state.TotalAmount - prvtotal) + total).toFixed(2));


        tax = Number((total * tax_rate).toFixed(2));
        total_tax = Number(((this.state.TotalTax - prvtax) + tax).toFixed(2));


        console.log("tax", tax, total_tax, tax_rate, prvtax)

        this.state.products[index].Total = total;
        this.state.products[index].Tax = tax;


        this.setState({ TotalAmount: sum });
        this.setState({ TotalTax: total_tax });
        
        this.forceUpdate()       
      
    };

    onSelectionHandle =() =>{    
    
      localStorage.setItem('ShoppingCart',JSON.stringify(this.state.CartArray));
      localStorage.setItem('AllProducts',JSON.stringify(this.state.products));   
      

      this.props.history.push('/ProductsList/ViewCart');
    };

    handleAccountSettingShow = () => {
        this.props.history.push('/AccountSettings');
    }
    handleSignOut = () => { }
    handleSignIn = () => { }

    render() {
        return (
          <div>
             
            
            <Card >
                    <div class="card-header container-fluid">                       

                        <div class="row">
                            <div class="col-md-10">
                                <h3>Product List</h3>
                            </div>
                           
                            <div class="col-md-1" >

                                <a href="#" class="fa fa-shopping-cart fa-2x black-cart" aria-hidden="true" onClick={(e) => { e.preventDefault(); this.onSelectionHandle(); }}></a>
                                <span class='badge badge-warning' id='lblCartCount'> {this.state.TotalNumber} </span>

                            </div>
                            {!this.state.isLoggedIn && (
                             
                                <div class="col-md-1" >
                                    <a href="#"  aria-hidden="true" onClick={(e) => { e.preventDefault(); this.handleSignIn(); }}> Sign In</a>

                                </div>
                            )}
                            {this.state.isLoggedIn && (
                               
                                <div class="col-md-1">
                                    <DropdownButton
                                        title="Action"
                                        id="dropdown-menu-align-right">
                                        <Dropdown.Item eventKey="1" onClick={() => { this.handleAccountSettingShow(); }}>Account Settings</Dropdown.Item>
                                        <Dropdown.Item eventKey="1" onClick={() => { this.handleSignOut(); }}>Sign Out</Dropdown.Item>
                                    </DropdownButton>

                                </div>
                           )}
                                
                        </div>
                    </div>
              
            
               
               <Card.Body>
               <Container fluid>
              
                 
                  <Row><Col xs={12} md={12}>
                      <Button variant="secondary" className='mb-3' onClick={(e) => { e.preventDefault(); this.AddSelectedToCart(); }}>Add Selected To Cart</Button>
                        
                  </Col></Row>
                    
                   
                    {this.state.products.map(((item, index) =>
                        <div>
                            {index < 1 && (
                                
                               <Row>
                                    <Col className="d-none d-xl-block d-lg-block  d-md-block" xs={12} md={1}></Col>
                                    <Col className="d-none d-xl-block d-lg-block  d-md-block" xs={12} sm={12} md={2} lg={2} xl={2}>  <h6>Title</h6> </Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={2} lg={2} xl={2}><h6>Description</h6></Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={1} lg={1} xl={1}><h6>Price</h6></Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={1} lg={1} xl={1}><h6>Qty</h6></Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={1} lg={1} xl={1}><h6>Tax</h6></Col>
                                    <Col className="d-none d-xl-block d-lg-block d-md-block" xs={12} sm={12} md={2} lg={2} xl={2}><h6>Total</h6></Col>
                                    <hr className="d-none d-xl-block d-lg-block d-md-block" style={{ color: 'black', backgroundColor: 'black', height: 1 }} />
                                </Row>
                                    
                            )}
                            <Row>
                                
                                
                                <Col xs={12} sm={12} md={1} key={'isSelected_'+item.ProductID}>
                                   <input type="checkbox" name={"isSelected"+index} checked= {item.isSelected}  onChange={e => this.handleCheckBox(e, index,item.ProductID)}/>
                                </Col>
                                
                                <Col xs={12} sm={12} md={2} key={'details_'+item.ProductID}>
                                  <p>  
                                        
                                        {(item.Image && (<Image src={item.Image} height='30px' width= '30px'   />))}
                                        {item.Title}
                                   </p>
                                </Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Description:</h6></Col>
                                <Col xs={8} sm={8} md={2} key={'Description_' + item.ProductID}>{item.Description}</Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Price:</h6></Col>
                                <Col xs={8} sm={8} md={1} key={'price_' + item.ProductID}>{item.Price}</Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Qty:</h6></Col>
                                <Col xs={8} sm={8}  md={1} key={'qty_'+item.ProductID}>
                                    <input type="number" name={"Qty" + index} id={"Qty" + index} style={{ width: "50px" }} defaultValue={item.Qty} value={item.value} min="0" onChange={e => this.handleQtyUpdate(e, index, item.Price, item.TaxRate)}/>
                                </Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Tax:</h6></Col>
                                <Col xs={8} sm={8} md={1} key={'tax_' + item.ProductID}> {item.Tax}</Col>

                                <Col className="d-xl-none d-lg-none d-md-none d-sm-block" xs={4} sm={4}><h6>Total:</h6></Col>
                                <Col xs={8} sm={8} md={2} key={'total_' + item.ProductID}>{item.Total}</Col>

                                <Col xs={12} md={2} key={'ad_'+item.ProductID}>
                                    <Button variant="secondary" size="sm" onClick={(e) =>{this.handleAddToCart(item,item.isSelected)}}>Add to cart</Button>             
                       
                                </Col>
                          </Row>
                    
                       </div>
                    ))}
                     <Row>
                      <hr style={{color: 'black',backgroundColor: 'black',height: 1}}/>
                    </Row>
                    <Row>                       
                         <Col xs={3} md={8}>Total : </Col>
                         <Col xs={9} md={4}>{this.state.TotalAmount}</Col>
                    </Row>
                    <Row>
                         <Col xs={3} md={7}>Tax : </Col>
                         <Col xs={9} md={5}>{this.state.TotalTax}</Col>
                        
                    </Row>
                    <Row>
                       <Col>
                         <Button variant="primary" style={{float: 'right'}} onClick={(e) => { e.preventDefault(); this.onSelectionHandle(); }}>ViewCart</Button>
                       </Col>
                        
                    </Row>
                  </Container>
                  
                </Card.Body>
                
            </Card> 
               
          </div>
        
            
            
          

          
             
        );
    }
}






export default withRouter(ProductsList);
