/*******************************************************************************************
Copyright © 2019 Xiippy.ai. All rights reserved. Australian patents awarded. PCT patent pending.

NOTES:

- No payment gateway SDK function is consumed directly. Interfaces are defined out of such interactions and then the interface is implemented for payment gateways. Design the interface with the most common members and data structures between different gateways. 
A proper factory or provider must instantiate an instance of the interface that is interacted with.
- Any major change made to SDKs should begin with the c# SDK with the mindset to keep the high-level syntax, structures and class names the same to minimise porting efforts to other languages. Do not use language specific features that don't exist in other languages. We are not in the business of doing the same thing from scratch multiple times in different forms.
- Pascal Case for naming conventions should be used for all languages
- No secret or passwords or keys must exist in the code when checked in

*******************************************************************************************/
 // loook at https://www.npmjs.com/package/js-crypto-utils
import React from 'reactn'
import { Auth } from 'aws-amplify';
import { API } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
//import  SecureWebCryptoBackedStorageProvider  from './SecureWebCryptoBackedStorageProvider';

//import "../assets/css/srwdevportal.css";

let yup = require('yup');

let schema = yup.object().shape({
    email: yup.string().required('Please enter a valid email address').email('Please enter a valid email address'),
    password: yup.string().required('Please enter your password')
});

const srp = require('secure-remote-password/client');

class SignIn extends React.Component {

    constructor(props) {
        super(props);


        this.state = {

            isbusy: false,
            buttonLabel: 'Sign In'

        };


    };


    async componentDidMount() {
        console.log('componentDidMount ()');
        document.title = "Xiippy - Developer's Portal - Log In";
       // await this.checkAuthentication();
       // await this.testSecureStorage();
    }

    testSecureStorage = async () => {

        console.log('componentDidMount ()');
        
       // var p = new SecureWebCryptoBackedStorageProvider('My Container ID');
       // await p.SetAsync("MyKey", "My Value");
       // var realValue = await p.GetAsync("MyKey");
      //  console.log(realValue);

    }

    checkAuthentication = async () => {
        console.log('checkAuthentication ()');
        try {
            var currentUser = await Auth.currentAuthenticatedUser();
            if (currentUser != null) {
                this.setState({ loaded: true, isAuthenticated: true });

                console.log(`checkAuthentication : currentUser: ${currentUser}`);
                this.props.history.push('/');
            }
        } catch (e) {
            console.log('no user authenticated');
        }
    }


    getQueryVariable = (variable) => {
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    generateSignInParams() {
        const clientEphemeral = srp.generateEphemeral();
        return (clientEphemeral);
    }


    handleSubmit = async (values, actions) => {

        actions.setStatus(undefined);

        this.setState({ isbusy: true, buttonLabel: 'Please Wait...' });

        var email_address = values.email;
        var password = values.password;
        var ClientEphemeral = this.generateSignInParams();
        var ClientEphemeralPublic = ClientEphemeral.public;


        let apiName = process.env.REACT_APP_epn;
        let path = process.env.REACT_APP_gds;
        let myInit = { // OPTIONAL
            headers: {
                "x-api-key": process.env.REACT_APP_ak
            }, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
                //name: 'param'
            },
            body: {
                "EmailAddress": email_address,
                "ClientEphemeralPublic": ClientEphemeralPublic
            }
        };

        console.log(myInit);

        console.log('about to call POST()');
        try {
            var response2 = await API.post(apiName, path, myInit);

            console.log('federatedSignIn: response from our own auth back-end:');
            console.log(response2);
            if (response2.data && response2.data.success) {

                console.log(`calculating client session...`);
                var Salt = response2.data.Salt;
                var ServerEphemeralPublic = response2.data.ServerEphemeralPublic;
                var pindex = response2.data.pindex;

                const privateKey = srp.derivePrivateKey(Salt, email_address, password);
                const clientSession = srp.deriveSession(ClientEphemeral.secret, ServerEphemeralPublic, Salt, email_address, privateKey);

                console.log(`clientSession :` + clientSession.key)
                //=> 2A6FF04E...

                console.log(clientSession.proof)

                var clientSessionProof = clientSession.proof
                

                if (clientSessionProof) {

                    let apiName2 = process.env.REACT_APP_epn;
                    let path2 = process.env.REACT_APP_siaa;
                    let myInit2 = { // OPTIONAL
                        headers: {
                            "x-api-key": process.env.REACT_APP_ak

                        }, // OPTIONAL
                        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
                        queryStringParameters: {  // OPTIONAL
                            //name: 'param'
                        },
                        body: {
                            "clientSessionProof": clientSessionProof,
                            "email_address": email_address,
                            "pindex": pindex

                        }
                    };

                    console.log(myInit2);

                    const u = {
                        email_address: values.email
                    };

                    console.log('about to call POST 2()');
                    try {
                        var response2 = await API.post(apiName2, path2, myInit2);

                        console.log('federatedSignIn: response from our own auth back-end:');
                        console.log(response2);
                        if (response2.data.login) {


                            // ok for the time we will be asking for a refreshtoken, we need the email and the refresh token that we must persist ourselves
                            console.log('federatedSignIn: storing stuff for later');
                            localStorage .setItem('iw_ea', values.email);

                            if (response2.data.RefreshToken != null)
                                localStorage .setItem('iw_rk', response2.data.RefreshToken);

                            if (response2.data.ReceiptWalletDeveloperID != null)
                                localStorage .setItem('is_id', response2.data.ReceiptWalletDeveloperID);


                            try {
                                var user = await Auth.federatedSignIn('developer', {
                                    token: response2.data.token,
                                    identity_id: response2.data.identityId,
                                    expires_at: response2.data.TokenDurationinSeconds * 1000 + new Date().getTime()
                                }, u);

                                console.log('federatedSignIn.expiry moment>' + response2.data.TokenDurationinSeconds * 1000 + new Date().getTime());


                                if (user != null) {

                                    console.log('federatedSignIn.user>:');
                                    console.log(user);

                                    var returnUrl = this.getQueryVariable('returnUrl');
                                    console.log('federatedSignIn.returnUrl>' + returnUrl);
                                    if (!returnUrl || returnUrl.startsWith('/auth')) returnUrl = '/';
                                    this.props.history.push(returnUrl);
                                }
                                else
                                    actions.setStatus({
                                        email: 'An error occurred trying to authenticate you.'
                                    });
                            }
                            catch (err) {
                                console.log('federatedSignIn.err>' + err);

                                actions.setStatus({
                                    email: 'An error occurred trying to authenticate you.'
                                });

                            }

                        } else {
                            console.log('could not log in....');

                            actions.setStatus({
                                email: 'Invalid email address or password.'
                            });
                        }

                    }
                    catch (e) {
                        console.log('error calling api' + e);

                        actions.setStatus({
                            email: 'An error occurred trying to authenticate you.'
                        });
                    }
                }
            }
        }
        catch (e) {
            console.log('error calling api' + e);

            actions.setStatus({
                email: 'An error occurred trying to authenticate you.'
            });
        }
        this.setState({ isbusy: false, buttonLabel: 'Sign In' });

    }

    /*
    handleSubmit = async (values, actions) => {

        actions.setStatus(undefined);

        this.setState({ isbusy: true, buttonLabel: 'Please Wait...' });


        let apiName = process.env.REACT_APP_epn;
        let path = process.env.REACT_APP_siaa;
        let myInit = { // OPTIONAL
            headers: {
                "x-api-key": process.env.REACT_APP_ak
            }, // OPTIONAL
            response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
                //name: 'param'
            },
            body: {
                "email_address": values.email,
                "pass": values.password,
                "pindex": 1,
                "GenerateAndReturnRefreshToken": true
            }
        };

        console.log(myInit);

        const u = {
            email_address: values.email
        };

        console.log('about to call POST()');
        try {
            var response = await API.post(apiName, path, myInit);

            console.log('federatedSignIn: response from our own auth back-end:');
            console.log(response);
            if (response.data.login) {


                // ok for the time we will be asking for a refreshtoken, we need the email and the refresh token that we must persist ourselves
                console.log('federatedSignIn: storing stuff for later');
                localStorage .setItem('iw_ea', values.email);

                if (response.data.RefreshToken != null)
                    localStorage .setItem('iw_rk', response.data.RefreshToken);

                if (response.data.ReceiptWalletDeveloperID != null)
                    localStorage .setItem('is_id', response.data.ReceiptWalletDeveloperID);


                try {
                    var user = await Auth.federatedSignIn('developer', {
                        token: response.data.token,
                        identity_id: response.data.identityId,
                        expires_at: response.data.TokenDurationinSeconds * 1000 + new Date().getTime()
                    }, u);

                    console.log('federatedSignIn.expiry moment>' + response.data.TokenDurationinSeconds * 1000 + new Date().getTime());


                    if (user != null) {

                        console.log('federatedSignIn.user>:');
                        console.log(user);

                        var returnUrl = this.getQueryVariable('returnUrl');
                        console.log('federatedSignIn.returnUrl>' + returnUrl);
                        if (!returnUrl || returnUrl.startsWith('/auth')) returnUrl = '/';
                        this.props.history.push(returnUrl);
                    }
                    else
                        actions.setStatus({
                            email: 'An error occurred trying to authenticate you.'
                        });
                }
                catch (err) {
                    console.log('federatedSignIn.err>' + err);

                    actions.setStatus({
                        email: 'An error occurred trying to authenticate you.'
                    });

                }

            } else {
                console.log('could not log in....');

                actions.setStatus({
                    email: 'Invalid email address or password.'
                });
            }

        }
        catch (e) {
            console.log('error calling api' + e);

            actions.setStatus({
                email: 'An error occurred trying to authenticate you.'
            });
        }

        this.setState({ isbusy: false, buttonLabel: 'Sign In' });

    } */





    render() {


        return (


            <div className="container">


                <div style={{ height: "calc(100vh)", display: "table", tableLayout: "fixed", width: "100%" }} >
                    <div style={{ display: "table-cell", width: "100%", verticalAlign: "middle" }}>



                        <div className="row drop-shadow" style={{ padding: "0", border: "1px solid #efefef", margin: "0" }}>
                            <div className="col-lg-6 d-none d-lg-block bg-login-image hidden-md hidden-sm hidden-xs" style={{ minHeight: "36em" }} ></div>
                            <div className="col-lg-6">
                                <div style={{ padding: "5em 5em 5em 5em" }}>
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4 textshadow">Welcome Back to Xiippy Developer's Portal!</h1>
                                    </div>

                                    <Formik
                                        validationSchema={schema}
                                        initialValues={{ email: '', password: '' }}
                                        onSubmit={this.handleSubmit}
                                        render={({ handleSubmit, handleChange, handleBlur, values, errors, status }) => (
                                            <Form>
                                                <div className="form-group">
                                                    <Field type="email" name="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Email Address" autoComplete="username" />
                                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                                </div>
                                                <div className="form-group">
                                                    <Field type="password" name="password" className="form-control form-control-user" id="exampleInputPassword" aria-describedby="emailHelp" placeholder="Password" autoComplete="current-password" />
                                                    <ErrorMessage name="password" component="div" className="text-danger" />
                                                </div>
                                                <button type="submit" disabled={this.state.isbusy} className="btn btn-primary btn-user btn-block">{this.state.buttonLabel}</button>

                                                {status && status.email ? (
                                                    <p className="text-danger text-center">{status.email}</p>)
                                                    :
                                                            /*(
                                                                errors.email && <p>Error: {errors.email}</p>
                                                            )*/ null

                                                }

                                            </Form>

                                        )}
                                    />

                                    <hr />
                                    <div className="text-center">
                                        <a className="small" href="/ForgotPassword">Forgot Password?</a>
                                    </div>
                                    <div className="text-center">
                                        <a className="small" href="/signup">Create a Xiippy Developer's Account!</a>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>




            //<div {...css(styles.container)}>
            //  {
            //    !this.state.showConfirmation && (
            //      <div {...css(styles.container)}>
            //        <input
            //          onChange={evt => this.onChange('username', evt.target.value)}
            //          {...css(styles.input)}
            //          placeholder='username'
            //        />
            //        <input
            //          type='password'
            //          onChange={evt => this.onChange('password', evt.target.value)}
            //          {...css(styles.input)}
            //          placeholder='password'
            //        />
            //        <div {...css(styles.button)} onClick={this.signIn}>
            //          <p {...css(styles.buttonText)}>Sign In</p>
            //        </div>
            //      </div>
            //    )
            //  }
            //  {
            //    this.state.showConfirmation && (
            //      <div>
            //        <input
            //          onChange={evt => this.onChange('authCode', evt.target.value)}
            //          {...css(styles.input)}
            //          placeholder='Confirmation Code'
            //        />
            //        <div {...css(styles.button)} onClick={this.confirmSignIn.bind(this)}>
            //          <p {...css(styles.buttonText)}>Confirm Sign In</p>
            //        </div>
            //      </div>
            //    )
            //  }
            //    </div>




        );
    }
}


export default withRouter(SignIn);
